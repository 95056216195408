//
// Verbatim copy of Bootstrap screen varaible settings
//
//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `@screen-xs` as of v3.0.1
$screen-xs:                  480px;
//** Deprecated `@screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs;
//** Deprecated `@screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min;

// Small screen / tablet
//** Deprecated `@screen-sm` as of v3.0.1
$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;
//** Deprecated `@screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min;

// Medium screen / desktop
//** Deprecated `@screen-md` as of v3.0.1
$screen-md:                  992px;
$screen-md-min:              $screen-md;
//** Deprecated `@screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min;

// Large screen / wide desktop
//** Deprecated `@screen-lg` as of v3.0.1
$screen-lg:                  1200px;
$screen-lg-min:              $screen-lg;
//** Deprecated `@screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);


// Definitions for extra large devices
$screen-xlg:                  1440px;
$screen-xlg-min:              $screen-xlg;
$screen-lg-max:              ($screen-xlg-min - 1);

/* END Screen definitions copied from bootstrap */

/*
 * Some mxins on that
 */
// mixins defined on that ("bs-"= bootsrap)
@mixin bs-xs {
  @media (max-width: $screen-xs) {
    @content;
  }
}
@mixin bs-gt-xs {
  @media (min-width: $screen-xs) {
    @content;
  }
}
@mixin bs-sm {
  @media (min-width: $screen-xs) and (max-width: $screen-sm) {
    @content;
  }
}
@mixin bs-gt-sm {
  @media (min-width: $screen-sm) {
    @content;
  }
}
@mixin bs-md {
  @media (min-width: $screen-sm) and (max-width: $screen-md) {
    @content;
  }
}
@mixin bs-gt-md {
  @media (min-width: $screen-md) {
    @content;
  }
}
@mixin bs-lg {
  @media (min-width: $screen-md) and (max-width: $screen-lg) {
    @content;
  }
}
@mixin bs-gt-lg {
  @media (min-width: $screen-lg) {
    @content;
  }
}
@mixin bs-xl {
  @media (min-width: $screen-lg) {
    @content;
  }
}
