.wi-wind {
  &.towards-0-deg {@include wind-rotate(0deg);}
  &.towards-1-deg {@include wind-rotate(1deg);}
  &.towards-2-deg {@include wind-rotate(2deg);}
  &.towards-3-deg {@include wind-rotate(3deg);}
  &.towards-4-deg {@include wind-rotate(4deg);}
  &.towards-5-deg {@include wind-rotate(5deg);}
  &.towards-6-deg {@include wind-rotate(6deg);}
  &.towards-7-deg {@include wind-rotate(7deg);}
  &.towards-8-deg {@include wind-rotate(8deg);}
  &.towards-9-deg {@include wind-rotate(9deg);}
  &.towards-10-deg {@include wind-rotate(10deg);}
  &.towards-11-deg {@include wind-rotate(11deg);}
  &.towards-12-deg {@include wind-rotate(12deg);}
  &.towards-13-deg {@include wind-rotate(13deg);}
  &.towards-14-deg {@include wind-rotate(14deg);}
  &.towards-15-deg {@include wind-rotate(15deg);}
  &.towards-16-deg {@include wind-rotate(16deg);}
  &.towards-17-deg {@include wind-rotate(17deg);}
  &.towards-18-deg {@include wind-rotate(18deg);}
  &.towards-19-deg {@include wind-rotate(19deg);}
  &.towards-20-deg {@include wind-rotate(20deg);}
  &.towards-21-deg {@include wind-rotate(21deg);}
  &.towards-22-deg {@include wind-rotate(22deg);}
  &.towards-23-deg {@include wind-rotate(23deg);}
  &.towards-24-deg {@include wind-rotate(24deg);}
  &.towards-25-deg {@include wind-rotate(25deg);}
  &.towards-26-deg {@include wind-rotate(26deg);}
  &.towards-27-deg {@include wind-rotate(27deg);}
  &.towards-28-deg {@include wind-rotate(28deg);}
  &.towards-29-deg {@include wind-rotate(29deg);}
  &.towards-30-deg {@include wind-rotate(30deg);}
  &.towards-31-deg {@include wind-rotate(31deg);}
  &.towards-32-deg {@include wind-rotate(32deg);}
  &.towards-33-deg {@include wind-rotate(33deg);}
  &.towards-34-deg {@include wind-rotate(34deg);}
  &.towards-35-deg {@include wind-rotate(35deg);}
  &.towards-36-deg {@include wind-rotate(36deg);}
  &.towards-37-deg {@include wind-rotate(37deg);}
  &.towards-38-deg {@include wind-rotate(38deg);}
  &.towards-39-deg {@include wind-rotate(39deg);}
  &.towards-40-deg {@include wind-rotate(40deg);}
  &.towards-41-deg {@include wind-rotate(41deg);}
  &.towards-42-deg {@include wind-rotate(42deg);}
  &.towards-43-deg {@include wind-rotate(43deg);}
  &.towards-44-deg {@include wind-rotate(44deg);}
  &.towards-45-deg {@include wind-rotate(45deg);}
  &.towards-46-deg {@include wind-rotate(46deg);}
  &.towards-47-deg {@include wind-rotate(47deg);}
  &.towards-48-deg {@include wind-rotate(48deg);}
  &.towards-49-deg {@include wind-rotate(49deg);}
  &.towards-50-deg {@include wind-rotate(50deg);}
  &.towards-51-deg {@include wind-rotate(51deg);}
  &.towards-52-deg {@include wind-rotate(52deg);}
  &.towards-53-deg {@include wind-rotate(53deg);}
  &.towards-54-deg {@include wind-rotate(54deg);}
  &.towards-55-deg {@include wind-rotate(55deg);}
  &.towards-56-deg {@include wind-rotate(56deg);}
  &.towards-57-deg {@include wind-rotate(57deg);}
  &.towards-58-deg {@include wind-rotate(58deg);}
  &.towards-59-deg {@include wind-rotate(59deg);}
  &.towards-60-deg {@include wind-rotate(60deg);}
  &.towards-61-deg {@include wind-rotate(61deg);}
  &.towards-62-deg {@include wind-rotate(62deg);}
  &.towards-63-deg {@include wind-rotate(63deg);}
  &.towards-64-deg {@include wind-rotate(64deg);}
  &.towards-65-deg {@include wind-rotate(65deg);}
  &.towards-66-deg {@include wind-rotate(66deg);}
  &.towards-67-deg {@include wind-rotate(67deg);}
  &.towards-68-deg {@include wind-rotate(68deg);}
  &.towards-69-deg {@include wind-rotate(69deg);}
  &.towards-70-deg {@include wind-rotate(70deg);}
  &.towards-71-deg {@include wind-rotate(71deg);}
  &.towards-72-deg {@include wind-rotate(72deg);}
  &.towards-73-deg {@include wind-rotate(73deg);}
  &.towards-74-deg {@include wind-rotate(74deg);}
  &.towards-75-deg {@include wind-rotate(75deg);}
  &.towards-76-deg {@include wind-rotate(76deg);}
  &.towards-77-deg {@include wind-rotate(77deg);}
  &.towards-78-deg {@include wind-rotate(78deg);}
  &.towards-79-deg {@include wind-rotate(79deg);}
  &.towards-80-deg {@include wind-rotate(80deg);}
  &.towards-81-deg {@include wind-rotate(81deg);}
  &.towards-82-deg {@include wind-rotate(82deg);}
  &.towards-83-deg {@include wind-rotate(83deg);}
  &.towards-84-deg {@include wind-rotate(84deg);}
  &.towards-85-deg {@include wind-rotate(85deg);}
  &.towards-86-deg {@include wind-rotate(86deg);}
  &.towards-87-deg {@include wind-rotate(87deg);}
  &.towards-88-deg {@include wind-rotate(88deg);}
  &.towards-89-deg {@include wind-rotate(89deg);}
  &.towards-90-deg {@include wind-rotate(90deg);}
  &.towards-91-deg {@include wind-rotate(91deg);}
  &.towards-92-deg {@include wind-rotate(92deg);}
  &.towards-93-deg {@include wind-rotate(93deg);}
  &.towards-94-deg {@include wind-rotate(94deg);}
  &.towards-95-deg {@include wind-rotate(95deg);}
  &.towards-96-deg {@include wind-rotate(96deg);}
  &.towards-97-deg {@include wind-rotate(97deg);}
  &.towards-98-deg {@include wind-rotate(98deg);}
  &.towards-99-deg {@include wind-rotate(99deg);}
  &.towards-100-deg {@include wind-rotate(100deg);}
  &.towards-101-deg {@include wind-rotate(101deg);}
  &.towards-102-deg {@include wind-rotate(102deg);}
  &.towards-103-deg {@include wind-rotate(103deg);}
  &.towards-104-deg {@include wind-rotate(104deg);}
  &.towards-105-deg {@include wind-rotate(105deg);}
  &.towards-106-deg {@include wind-rotate(106deg);}
  &.towards-107-deg {@include wind-rotate(107deg);}
  &.towards-108-deg {@include wind-rotate(108deg);}
  &.towards-109-deg {@include wind-rotate(109deg);}
  &.towards-110-deg {@include wind-rotate(110deg);}
  &.towards-111-deg {@include wind-rotate(111deg);}
  &.towards-112-deg {@include wind-rotate(112deg);}
  &.towards-113-deg {@include wind-rotate(113deg);}
  &.towards-114-deg {@include wind-rotate(114deg);}
  &.towards-115-deg {@include wind-rotate(115deg);}
  &.towards-116-deg {@include wind-rotate(116deg);}
  &.towards-117-deg {@include wind-rotate(117deg);}
  &.towards-118-deg {@include wind-rotate(118deg);}
  &.towards-119-deg {@include wind-rotate(119deg);}
  &.towards-120-deg {@include wind-rotate(120deg);}
  &.towards-121-deg {@include wind-rotate(121deg);}
  &.towards-122-deg {@include wind-rotate(122deg);}
  &.towards-123-deg {@include wind-rotate(123deg);}
  &.towards-124-deg {@include wind-rotate(124deg);}
  &.towards-125-deg {@include wind-rotate(125deg);}
  &.towards-126-deg {@include wind-rotate(126deg);}
  &.towards-127-deg {@include wind-rotate(127deg);}
  &.towards-128-deg {@include wind-rotate(128deg);}
  &.towards-129-deg {@include wind-rotate(129deg);}
  &.towards-130-deg {@include wind-rotate(130deg);}
  &.towards-131-deg {@include wind-rotate(131deg);}
  &.towards-132-deg {@include wind-rotate(132deg);}
  &.towards-133-deg {@include wind-rotate(133deg);}
  &.towards-134-deg {@include wind-rotate(134deg);}
  &.towards-135-deg {@include wind-rotate(135deg);}
  &.towards-136-deg {@include wind-rotate(136deg);}
  &.towards-137-deg {@include wind-rotate(137deg);}
  &.towards-138-deg {@include wind-rotate(138deg);}
  &.towards-139-deg {@include wind-rotate(139deg);}
  &.towards-140-deg {@include wind-rotate(140deg);}
  &.towards-141-deg {@include wind-rotate(141deg);}
  &.towards-142-deg {@include wind-rotate(142deg);}
  &.towards-143-deg {@include wind-rotate(143deg);}
  &.towards-144-deg {@include wind-rotate(144deg);}
  &.towards-145-deg {@include wind-rotate(145deg);}
  &.towards-146-deg {@include wind-rotate(146deg);}
  &.towards-147-deg {@include wind-rotate(147deg);}
  &.towards-148-deg {@include wind-rotate(148deg);}
  &.towards-149-deg {@include wind-rotate(149deg);}
  &.towards-150-deg {@include wind-rotate(150deg);}
  &.towards-151-deg {@include wind-rotate(151deg);}
  &.towards-152-deg {@include wind-rotate(152deg);}
  &.towards-153-deg {@include wind-rotate(153deg);}
  &.towards-154-deg {@include wind-rotate(154deg);}
  &.towards-155-deg {@include wind-rotate(155deg);}
  &.towards-156-deg {@include wind-rotate(156deg);}
  &.towards-157-deg {@include wind-rotate(157deg);}
  &.towards-158-deg {@include wind-rotate(158deg);}
  &.towards-159-deg {@include wind-rotate(159deg);}
  &.towards-160-deg {@include wind-rotate(160deg);}
  &.towards-161-deg {@include wind-rotate(161deg);}
  &.towards-162-deg {@include wind-rotate(162deg);}
  &.towards-163-deg {@include wind-rotate(163deg);}
  &.towards-164-deg {@include wind-rotate(164deg);}
  &.towards-165-deg {@include wind-rotate(165deg);}
  &.towards-166-deg {@include wind-rotate(166deg);}
  &.towards-167-deg {@include wind-rotate(167deg);}
  &.towards-168-deg {@include wind-rotate(168deg);}
  &.towards-169-deg {@include wind-rotate(169deg);}
  &.towards-170-deg {@include wind-rotate(170deg);}
  &.towards-171-deg {@include wind-rotate(171deg);}
  &.towards-172-deg {@include wind-rotate(172deg);}
  &.towards-173-deg {@include wind-rotate(173deg);}
  &.towards-174-deg {@include wind-rotate(174deg);}
  &.towards-175-deg {@include wind-rotate(175deg);}
  &.towards-176-deg {@include wind-rotate(176deg);}
  &.towards-177-deg {@include wind-rotate(177deg);}
  &.towards-178-deg {@include wind-rotate(178deg);}
  &.towards-179-deg {@include wind-rotate(179deg);}
  &.towards-180-deg {@include wind-rotate(180deg);}
  &.towards-181-deg {@include wind-rotate(181deg);}
  &.towards-182-deg {@include wind-rotate(182deg);}
  &.towards-183-deg {@include wind-rotate(183deg);}
  &.towards-184-deg {@include wind-rotate(184deg);}
  &.towards-185-deg {@include wind-rotate(185deg);}
  &.towards-186-deg {@include wind-rotate(186deg);}
  &.towards-187-deg {@include wind-rotate(187deg);}
  &.towards-188-deg {@include wind-rotate(188deg);}
  &.towards-189-deg {@include wind-rotate(189deg);}
  &.towards-190-deg {@include wind-rotate(190deg);}
  &.towards-191-deg {@include wind-rotate(191deg);}
  &.towards-192-deg {@include wind-rotate(192deg);}
  &.towards-193-deg {@include wind-rotate(193deg);}
  &.towards-194-deg {@include wind-rotate(194deg);}
  &.towards-195-deg {@include wind-rotate(195deg);}
  &.towards-196-deg {@include wind-rotate(196deg);}
  &.towards-197-deg {@include wind-rotate(197deg);}
  &.towards-198-deg {@include wind-rotate(198deg);}
  &.towards-199-deg {@include wind-rotate(199deg);}
  &.towards-200-deg {@include wind-rotate(200deg);}
  &.towards-201-deg {@include wind-rotate(201deg);}
  &.towards-202-deg {@include wind-rotate(202deg);}
  &.towards-203-deg {@include wind-rotate(203deg);}
  &.towards-204-deg {@include wind-rotate(204deg);}
  &.towards-205-deg {@include wind-rotate(205deg);}
  &.towards-206-deg {@include wind-rotate(206deg);}
  &.towards-207-deg {@include wind-rotate(207deg);}
  &.towards-208-deg {@include wind-rotate(208deg);}
  &.towards-209-deg {@include wind-rotate(209deg);}
  &.towards-210-deg {@include wind-rotate(210deg);}
  &.towards-211-deg {@include wind-rotate(211deg);}
  &.towards-212-deg {@include wind-rotate(212deg);}
  &.towards-213-deg {@include wind-rotate(213deg);}
  &.towards-214-deg {@include wind-rotate(214deg);}
  &.towards-215-deg {@include wind-rotate(215deg);}
  &.towards-216-deg {@include wind-rotate(216deg);}
  &.towards-217-deg {@include wind-rotate(217deg);}
  &.towards-218-deg {@include wind-rotate(218deg);}
  &.towards-219-deg {@include wind-rotate(219deg);}
  &.towards-220-deg {@include wind-rotate(220deg);}
  &.towards-221-deg {@include wind-rotate(221deg);}
  &.towards-222-deg {@include wind-rotate(222deg);}
  &.towards-223-deg {@include wind-rotate(223deg);}
  &.towards-224-deg {@include wind-rotate(224deg);}
  &.towards-225-deg {@include wind-rotate(225deg);}
  &.towards-226-deg {@include wind-rotate(226deg);}
  &.towards-227-deg {@include wind-rotate(227deg);}
  &.towards-228-deg {@include wind-rotate(228deg);}
  &.towards-229-deg {@include wind-rotate(229deg);}
  &.towards-230-deg {@include wind-rotate(230deg);}
  &.towards-231-deg {@include wind-rotate(231deg);}
  &.towards-232-deg {@include wind-rotate(232deg);}
  &.towards-233-deg {@include wind-rotate(233deg);}
  &.towards-234-deg {@include wind-rotate(234deg);}
  &.towards-235-deg {@include wind-rotate(235deg);}
  &.towards-236-deg {@include wind-rotate(236deg);}
  &.towards-237-deg {@include wind-rotate(237deg);}
  &.towards-238-deg {@include wind-rotate(238deg);}
  &.towards-239-deg {@include wind-rotate(239deg);}
  &.towards-240-deg {@include wind-rotate(240deg);}
  &.towards-241-deg {@include wind-rotate(241deg);}
  &.towards-242-deg {@include wind-rotate(242deg);}
  &.towards-243-deg {@include wind-rotate(243deg);}
  &.towards-244-deg {@include wind-rotate(244deg);}
  &.towards-245-deg {@include wind-rotate(245deg);}
  &.towards-246-deg {@include wind-rotate(246deg);}
  &.towards-247-deg {@include wind-rotate(247deg);}
  &.towards-248-deg {@include wind-rotate(248deg);}
  &.towards-249-deg {@include wind-rotate(249deg);}
  &.towards-250-deg {@include wind-rotate(250deg);}
  &.towards-251-deg {@include wind-rotate(251deg);}
  &.towards-252-deg {@include wind-rotate(252deg);}
  &.towards-253-deg {@include wind-rotate(253deg);}
  &.towards-254-deg {@include wind-rotate(254deg);}
  &.towards-255-deg {@include wind-rotate(255deg);}
  &.towards-256-deg {@include wind-rotate(256deg);}
  &.towards-257-deg {@include wind-rotate(257deg);}
  &.towards-258-deg {@include wind-rotate(258deg);}
  &.towards-259-deg {@include wind-rotate(259deg);}
  &.towards-260-deg {@include wind-rotate(260deg);}
  &.towards-261-deg {@include wind-rotate(261deg);}
  &.towards-262-deg {@include wind-rotate(262deg);}
  &.towards-263-deg {@include wind-rotate(263deg);}
  &.towards-264-deg {@include wind-rotate(264deg);}
  &.towards-265-deg {@include wind-rotate(265deg);}
  &.towards-266-deg {@include wind-rotate(266deg);}
  &.towards-267-deg {@include wind-rotate(267deg);}
  &.towards-268-deg {@include wind-rotate(268deg);}
  &.towards-269-deg {@include wind-rotate(269deg);}
  &.towards-270-deg {@include wind-rotate(270deg);}
  &.towards-271-deg {@include wind-rotate(271deg);}
  &.towards-272-deg {@include wind-rotate(272deg);}
  &.towards-273-deg {@include wind-rotate(273deg);}
  &.towards-274-deg {@include wind-rotate(274deg);}
  &.towards-275-deg {@include wind-rotate(275deg);}
  &.towards-276-deg {@include wind-rotate(276deg);}
  &.towards-277-deg {@include wind-rotate(277deg);}
  &.towards-278-deg {@include wind-rotate(278deg);}
  &.towards-279-deg {@include wind-rotate(279deg);}
  &.towards-280-deg {@include wind-rotate(280deg);}
  &.towards-281-deg {@include wind-rotate(281deg);}
  &.towards-282-deg {@include wind-rotate(282deg);}
  &.towards-283-deg {@include wind-rotate(283deg);}
  &.towards-284-deg {@include wind-rotate(284deg);}
  &.towards-285-deg {@include wind-rotate(285deg);}
  &.towards-286-deg {@include wind-rotate(286deg);}
  &.towards-287-deg {@include wind-rotate(287deg);}
  &.towards-288-deg {@include wind-rotate(288deg);}
  &.towards-289-deg {@include wind-rotate(289deg);}
  &.towards-290-deg {@include wind-rotate(290deg);}
  &.towards-291-deg {@include wind-rotate(291deg);}
  &.towards-292-deg {@include wind-rotate(292deg);}
  &.towards-293-deg {@include wind-rotate(293deg);}
  &.towards-294-deg {@include wind-rotate(294deg);}
  &.towards-295-deg {@include wind-rotate(295deg);}
  &.towards-296-deg {@include wind-rotate(296deg);}
  &.towards-297-deg {@include wind-rotate(297deg);}
  &.towards-298-deg {@include wind-rotate(298deg);}
  &.towards-299-deg {@include wind-rotate(299deg);}
  &.towards-300-deg {@include wind-rotate(300deg);}
  &.towards-301-deg {@include wind-rotate(301deg);}
  &.towards-302-deg {@include wind-rotate(302deg);}
  &.towards-303-deg {@include wind-rotate(303deg);}
  &.towards-304-deg {@include wind-rotate(304deg);}
  &.towards-305-deg {@include wind-rotate(305deg);}
  &.towards-306-deg {@include wind-rotate(306deg);}
  &.towards-307-deg {@include wind-rotate(307deg);}
  &.towards-308-deg {@include wind-rotate(308deg);}
  &.towards-309-deg {@include wind-rotate(309deg);}
  &.towards-310-deg {@include wind-rotate(310deg);}
  &.towards-311-deg {@include wind-rotate(311deg);}
  &.towards-312-deg {@include wind-rotate(312deg);}
  &.towards-313-deg {@include wind-rotate(313deg);}
  &.towards-314-deg {@include wind-rotate(314deg);}
  &.towards-315-deg {@include wind-rotate(315deg);}
  &.towards-316-deg {@include wind-rotate(316deg);}
  &.towards-317-deg {@include wind-rotate(317deg);}
  &.towards-318-deg {@include wind-rotate(318deg);}
  &.towards-319-deg {@include wind-rotate(319deg);}
  &.towards-320-deg {@include wind-rotate(320deg);}
  &.towards-321-deg {@include wind-rotate(321deg);}
  &.towards-322-deg {@include wind-rotate(322deg);}
  &.towards-323-deg {@include wind-rotate(323deg);}
  &.towards-324-deg {@include wind-rotate(324deg);}
  &.towards-325-deg {@include wind-rotate(325deg);}
  &.towards-326-deg {@include wind-rotate(326deg);}
  &.towards-327-deg {@include wind-rotate(327deg);}
  &.towards-328-deg {@include wind-rotate(328deg);}
  &.towards-329-deg {@include wind-rotate(329deg);}
  &.towards-330-deg {@include wind-rotate(330deg);}
  &.towards-331-deg {@include wind-rotate(331deg);}
  &.towards-332-deg {@include wind-rotate(332deg);}
  &.towards-333-deg {@include wind-rotate(333deg);}
  &.towards-334-deg {@include wind-rotate(334deg);}
  &.towards-335-deg {@include wind-rotate(335deg);}
  &.towards-336-deg {@include wind-rotate(336deg);}
  &.towards-337-deg {@include wind-rotate(337deg);}
  &.towards-338-deg {@include wind-rotate(338deg);}
  &.towards-339-deg {@include wind-rotate(339deg);}
  &.towards-340-deg {@include wind-rotate(340deg);}
  &.towards-341-deg {@include wind-rotate(341deg);}
  &.towards-342-deg {@include wind-rotate(342deg);}
  &.towards-343-deg {@include wind-rotate(343deg);}
  &.towards-344-deg {@include wind-rotate(344deg);}
  &.towards-345-deg {@include wind-rotate(345deg);}
  &.towards-346-deg {@include wind-rotate(346deg);}
  &.towards-347-deg {@include wind-rotate(347deg);}
  &.towards-348-deg {@include wind-rotate(348deg);}
  &.towards-349-deg {@include wind-rotate(349deg);}
  &.towards-350-deg {@include wind-rotate(350deg);}
  &.towards-351-deg {@include wind-rotate(351deg);}
  &.towards-352-deg {@include wind-rotate(352deg);}
  &.towards-353-deg {@include wind-rotate(353deg);}
  &.towards-354-deg {@include wind-rotate(354deg);}
  &.towards-355-deg {@include wind-rotate(355deg);}
  &.towards-356-deg {@include wind-rotate(356deg);}
  &.towards-357-deg {@include wind-rotate(357deg);}
  &.towards-358-deg {@include wind-rotate(358deg);}
  &.towards-359-deg {@include wind-rotate(359deg);}
  &.towards-360-deg {@include wind-rotate(0deg);}
  &.from-0-deg {@include wind-rotate(180deg);}
  &.from-1-deg {@include wind-rotate(181deg);}
  &.from-2-deg {@include wind-rotate(182deg);}
  &.from-3-deg {@include wind-rotate(183deg);}
  &.from-4-deg {@include wind-rotate(184deg);}
  &.from-5-deg {@include wind-rotate(185deg);}
  &.from-6-deg {@include wind-rotate(186deg);}
  &.from-7-deg {@include wind-rotate(187deg);}
  &.from-8-deg {@include wind-rotate(188deg);}
  &.from-9-deg {@include wind-rotate(189deg);}
  &.from-10-deg {@include wind-rotate(190deg);}
  &.from-11-deg {@include wind-rotate(191deg);}
  &.from-12-deg {@include wind-rotate(192deg);}
  &.from-13-deg {@include wind-rotate(193deg);}
  &.from-14-deg {@include wind-rotate(194deg);}
  &.from-15-deg {@include wind-rotate(195deg);}
  &.from-16-deg {@include wind-rotate(196deg);}
  &.from-17-deg {@include wind-rotate(197deg);}
  &.from-18-deg {@include wind-rotate(198deg);}
  &.from-19-deg {@include wind-rotate(199deg);}
  &.from-20-deg {@include wind-rotate(200deg);}
  &.from-21-deg {@include wind-rotate(201deg);}
  &.from-22-deg {@include wind-rotate(202deg);}
  &.from-23-deg {@include wind-rotate(203deg);}
  &.from-24-deg {@include wind-rotate(204deg);}
  &.from-25-deg {@include wind-rotate(205deg);}
  &.from-26-deg {@include wind-rotate(206deg);}
  &.from-27-deg {@include wind-rotate(207deg);}
  &.from-28-deg {@include wind-rotate(208deg);}
  &.from-29-deg {@include wind-rotate(209deg);}
  &.from-30-deg {@include wind-rotate(210deg);}
  &.from-31-deg {@include wind-rotate(211deg);}
  &.from-32-deg {@include wind-rotate(212deg);}
  &.from-33-deg {@include wind-rotate(213deg);}
  &.from-34-deg {@include wind-rotate(214deg);}
  &.from-35-deg {@include wind-rotate(215deg);}
  &.from-36-deg {@include wind-rotate(216deg);}
  &.from-37-deg {@include wind-rotate(217deg);}
  &.from-38-deg {@include wind-rotate(218deg);}
  &.from-39-deg {@include wind-rotate(219deg);}
  &.from-40-deg {@include wind-rotate(220deg);}
  &.from-41-deg {@include wind-rotate(221deg);}
  &.from-42-deg {@include wind-rotate(222deg);}
  &.from-43-deg {@include wind-rotate(223deg);}
  &.from-44-deg {@include wind-rotate(224deg);}
  &.from-45-deg {@include wind-rotate(225deg);}
  &.from-46-deg {@include wind-rotate(226deg);}
  &.from-47-deg {@include wind-rotate(227deg);}
  &.from-48-deg {@include wind-rotate(228deg);}
  &.from-49-deg {@include wind-rotate(229deg);}
  &.from-50-deg {@include wind-rotate(230deg);}
  &.from-51-deg {@include wind-rotate(231deg);}
  &.from-52-deg {@include wind-rotate(232deg);}
  &.from-53-deg {@include wind-rotate(233deg);}
  &.from-54-deg {@include wind-rotate(234deg);}
  &.from-55-deg {@include wind-rotate(235deg);}
  &.from-56-deg {@include wind-rotate(236deg);}
  &.from-57-deg {@include wind-rotate(237deg);}
  &.from-58-deg {@include wind-rotate(238deg);}
  &.from-59-deg {@include wind-rotate(239deg);}
  &.from-60-deg {@include wind-rotate(240deg);}
  &.from-61-deg {@include wind-rotate(241deg);}
  &.from-62-deg {@include wind-rotate(242deg);}
  &.from-63-deg {@include wind-rotate(243deg);}
  &.from-64-deg {@include wind-rotate(244deg);}
  &.from-65-deg {@include wind-rotate(245deg);}
  &.from-66-deg {@include wind-rotate(246deg);}
  &.from-67-deg {@include wind-rotate(247deg);}
  &.from-68-deg {@include wind-rotate(248deg);}
  &.from-69-deg {@include wind-rotate(249deg);}
  &.from-70-deg {@include wind-rotate(250deg);}
  &.from-71-deg {@include wind-rotate(251deg);}
  &.from-72-deg {@include wind-rotate(252deg);}
  &.from-73-deg {@include wind-rotate(253deg);}
  &.from-74-deg {@include wind-rotate(254deg);}
  &.from-75-deg {@include wind-rotate(255deg);}
  &.from-76-deg {@include wind-rotate(256deg);}
  &.from-77-deg {@include wind-rotate(257deg);}
  &.from-78-deg {@include wind-rotate(258deg);}
  &.from-79-deg {@include wind-rotate(259deg);}
  &.from-80-deg {@include wind-rotate(260deg);}
  &.from-81-deg {@include wind-rotate(261deg);}
  &.from-82-deg {@include wind-rotate(262deg);}
  &.from-83-deg {@include wind-rotate(263deg);}
  &.from-84-deg {@include wind-rotate(264deg);}
  &.from-85-deg {@include wind-rotate(265deg);}
  &.from-86-deg {@include wind-rotate(266deg);}
  &.from-87-deg {@include wind-rotate(267deg);}
  &.from-88-deg {@include wind-rotate(268deg);}
  &.from-89-deg {@include wind-rotate(269deg);}
  &.from-90-deg {@include wind-rotate(270deg);}
  &.from-91-deg {@include wind-rotate(271deg);}
  &.from-92-deg {@include wind-rotate(272deg);}
  &.from-93-deg {@include wind-rotate(273deg);}
  &.from-94-deg {@include wind-rotate(274deg);}
  &.from-95-deg {@include wind-rotate(275deg);}
  &.from-96-deg {@include wind-rotate(276deg);}
  &.from-97-deg {@include wind-rotate(277deg);}
  &.from-98-deg {@include wind-rotate(278deg);}
  &.from-99-deg {@include wind-rotate(279deg);}
  &.from-100-deg {@include wind-rotate(280deg);}
  &.from-101-deg {@include wind-rotate(281deg);}
  &.from-102-deg {@include wind-rotate(282deg);}
  &.from-103-deg {@include wind-rotate(283deg);}
  &.from-104-deg {@include wind-rotate(284deg);}
  &.from-105-deg {@include wind-rotate(285deg);}
  &.from-106-deg {@include wind-rotate(286deg);}
  &.from-107-deg {@include wind-rotate(287deg);}
  &.from-108-deg {@include wind-rotate(288deg);}
  &.from-109-deg {@include wind-rotate(289deg);}
  &.from-110-deg {@include wind-rotate(290deg);}
  &.from-111-deg {@include wind-rotate(291deg);}
  &.from-112-deg {@include wind-rotate(292deg);}
  &.from-113-deg {@include wind-rotate(293deg);}
  &.from-114-deg {@include wind-rotate(294deg);}
  &.from-115-deg {@include wind-rotate(295deg);}
  &.from-116-deg {@include wind-rotate(296deg);}
  &.from-117-deg {@include wind-rotate(297deg);}
  &.from-118-deg {@include wind-rotate(298deg);}
  &.from-119-deg {@include wind-rotate(299deg);}
  &.from-120-deg {@include wind-rotate(300deg);}
  &.from-121-deg {@include wind-rotate(301deg);}
  &.from-122-deg {@include wind-rotate(302deg);}
  &.from-123-deg {@include wind-rotate(303deg);}
  &.from-124-deg {@include wind-rotate(304deg);}
  &.from-125-deg {@include wind-rotate(305deg);}
  &.from-126-deg {@include wind-rotate(306deg);}
  &.from-127-deg {@include wind-rotate(307deg);}
  &.from-128-deg {@include wind-rotate(308deg);}
  &.from-129-deg {@include wind-rotate(309deg);}
  &.from-130-deg {@include wind-rotate(310deg);}
  &.from-131-deg {@include wind-rotate(311deg);}
  &.from-132-deg {@include wind-rotate(312deg);}
  &.from-133-deg {@include wind-rotate(313deg);}
  &.from-134-deg {@include wind-rotate(314deg);}
  &.from-135-deg {@include wind-rotate(315deg);}
  &.from-136-deg {@include wind-rotate(316deg);}
  &.from-137-deg {@include wind-rotate(317deg);}
  &.from-138-deg {@include wind-rotate(318deg);}
  &.from-139-deg {@include wind-rotate(319deg);}
  &.from-140-deg {@include wind-rotate(320deg);}
  &.from-141-deg {@include wind-rotate(321deg);}
  &.from-142-deg {@include wind-rotate(322deg);}
  &.from-143-deg {@include wind-rotate(323deg);}
  &.from-144-deg {@include wind-rotate(324deg);}
  &.from-145-deg {@include wind-rotate(325deg);}
  &.from-146-deg {@include wind-rotate(326deg);}
  &.from-147-deg {@include wind-rotate(327deg);}
  &.from-148-deg {@include wind-rotate(328deg);}
  &.from-149-deg {@include wind-rotate(329deg);}
  &.from-150-deg {@include wind-rotate(330deg);}
  &.from-151-deg {@include wind-rotate(331deg);}
  &.from-152-deg {@include wind-rotate(332deg);}
  &.from-153-deg {@include wind-rotate(333deg);}
  &.from-154-deg {@include wind-rotate(334deg);}
  &.from-155-deg {@include wind-rotate(335deg);}
  &.from-156-deg {@include wind-rotate(336deg);}
  &.from-157-deg {@include wind-rotate(337deg);}
  &.from-158-deg {@include wind-rotate(338deg);}
  &.from-159-deg {@include wind-rotate(339deg);}
  &.from-160-deg {@include wind-rotate(340deg);}
  &.from-161-deg {@include wind-rotate(341deg);}
  &.from-162-deg {@include wind-rotate(342deg);}
  &.from-163-deg {@include wind-rotate(343deg);}
  &.from-164-deg {@include wind-rotate(344deg);}
  &.from-165-deg {@include wind-rotate(345deg);}
  &.from-166-deg {@include wind-rotate(346deg);}
  &.from-167-deg {@include wind-rotate(347deg);}
  &.from-168-deg {@include wind-rotate(348deg);}
  &.from-169-deg {@include wind-rotate(349deg);}
  &.from-170-deg {@include wind-rotate(350deg);}
  &.from-171-deg {@include wind-rotate(351deg);}
  &.from-172-deg {@include wind-rotate(352deg);}
  &.from-173-deg {@include wind-rotate(353deg);}
  &.from-174-deg {@include wind-rotate(354deg);}
  &.from-175-deg {@include wind-rotate(355deg);}
  &.from-176-deg {@include wind-rotate(356deg);}
  &.from-177-deg {@include wind-rotate(357deg);}
  &.from-178-deg {@include wind-rotate(358deg);}
  &.from-179-deg {@include wind-rotate(359deg);}
  &.from-180-deg {@include wind-rotate(0deg);}
  &.from-181-deg {@include wind-rotate(1deg);}
  &.from-182-deg {@include wind-rotate(2deg);}
  &.from-183-deg {@include wind-rotate(3deg);}
  &.from-184-deg {@include wind-rotate(4deg);}
  &.from-185-deg {@include wind-rotate(5deg);}
  &.from-186-deg {@include wind-rotate(6deg);}
  &.from-187-deg {@include wind-rotate(7deg);}
  &.from-188-deg {@include wind-rotate(8deg);}
  &.from-189-deg {@include wind-rotate(9deg);}
  &.from-190-deg {@include wind-rotate(10deg);}
  &.from-191-deg {@include wind-rotate(11deg);}
  &.from-192-deg {@include wind-rotate(12deg);}
  &.from-193-deg {@include wind-rotate(13deg);}
  &.from-194-deg {@include wind-rotate(14deg);}
  &.from-195-deg {@include wind-rotate(15deg);}
  &.from-196-deg {@include wind-rotate(16deg);}
  &.from-197-deg {@include wind-rotate(17deg);}
  &.from-198-deg {@include wind-rotate(18deg);}
  &.from-199-deg {@include wind-rotate(19deg);}
  &.from-200-deg {@include wind-rotate(20deg);}
  &.from-201-deg {@include wind-rotate(21deg);}
  &.from-202-deg {@include wind-rotate(22deg);}
  &.from-203-deg {@include wind-rotate(23deg);}
  &.from-204-deg {@include wind-rotate(24deg);}
  &.from-205-deg {@include wind-rotate(25deg);}
  &.from-206-deg {@include wind-rotate(26deg);}
  &.from-207-deg {@include wind-rotate(27deg);}
  &.from-208-deg {@include wind-rotate(28deg);}
  &.from-209-deg {@include wind-rotate(29deg);}
  &.from-210-deg {@include wind-rotate(30deg);}
  &.from-211-deg {@include wind-rotate(31deg);}
  &.from-212-deg {@include wind-rotate(32deg);}
  &.from-213-deg {@include wind-rotate(33deg);}
  &.from-214-deg {@include wind-rotate(34deg);}
  &.from-215-deg {@include wind-rotate(35deg);}
  &.from-216-deg {@include wind-rotate(36deg);}
  &.from-217-deg {@include wind-rotate(37deg);}
  &.from-218-deg {@include wind-rotate(38deg);}
  &.from-219-deg {@include wind-rotate(39deg);}
  &.from-220-deg {@include wind-rotate(40deg);}
  &.from-221-deg {@include wind-rotate(41deg);}
  &.from-222-deg {@include wind-rotate(42deg);}
  &.from-223-deg {@include wind-rotate(43deg);}
  &.from-224-deg {@include wind-rotate(44deg);}
  &.from-225-deg {@include wind-rotate(45deg);}
  &.from-226-deg {@include wind-rotate(46deg);}
  &.from-227-deg {@include wind-rotate(47deg);}
  &.from-228-deg {@include wind-rotate(48deg);}
  &.from-229-deg {@include wind-rotate(49deg);}
  &.from-230-deg {@include wind-rotate(50deg);}
  &.from-231-deg {@include wind-rotate(51deg);}
  &.from-232-deg {@include wind-rotate(52deg);}
  &.from-233-deg {@include wind-rotate(53deg);}
  &.from-234-deg {@include wind-rotate(54deg);}
  &.from-235-deg {@include wind-rotate(55deg);}
  &.from-236-deg {@include wind-rotate(56deg);}
  &.from-237-deg {@include wind-rotate(57deg);}
  &.from-238-deg {@include wind-rotate(58deg);}
  &.from-239-deg {@include wind-rotate(59deg);}
  &.from-240-deg {@include wind-rotate(60deg);}
  &.from-241-deg {@include wind-rotate(61deg);}
  &.from-242-deg {@include wind-rotate(62deg);}
  &.from-243-deg {@include wind-rotate(63deg);}
  &.from-244-deg {@include wind-rotate(64deg);}
  &.from-245-deg {@include wind-rotate(65deg);}
  &.from-246-deg {@include wind-rotate(66deg);}
  &.from-247-deg {@include wind-rotate(67deg);}
  &.from-248-deg {@include wind-rotate(68deg);}
  &.from-249-deg {@include wind-rotate(69deg);}
  &.from-250-deg {@include wind-rotate(70deg);}
  &.from-251-deg {@include wind-rotate(71deg);}
  &.from-252-deg {@include wind-rotate(72deg);}
  &.from-253-deg {@include wind-rotate(73deg);}
  &.from-254-deg {@include wind-rotate(74deg);}
  &.from-255-deg {@include wind-rotate(75deg);}
  &.from-256-deg {@include wind-rotate(76deg);}
  &.from-257-deg {@include wind-rotate(77deg);}
  &.from-258-deg {@include wind-rotate(78deg);}
  &.from-259-deg {@include wind-rotate(79deg);}
  &.from-260-deg {@include wind-rotate(80deg);}
  &.from-261-deg {@include wind-rotate(81deg);}
  &.from-262-deg {@include wind-rotate(82deg);}
  &.from-263-deg {@include wind-rotate(83deg);}
  &.from-264-deg {@include wind-rotate(84deg);}
  &.from-265-deg {@include wind-rotate(85deg);}
  &.from-266-deg {@include wind-rotate(86deg);}
  &.from-267-deg {@include wind-rotate(87deg);}
  &.from-268-deg {@include wind-rotate(88deg);}
  &.from-269-deg {@include wind-rotate(89deg);}
  &.from-270-deg {@include wind-rotate(90deg);}
  &.from-271-deg {@include wind-rotate(91deg);}
  &.from-272-deg {@include wind-rotate(92deg);}
  &.from-273-deg {@include wind-rotate(93deg);}
  &.from-274-deg {@include wind-rotate(94deg);}
  &.from-275-deg {@include wind-rotate(95deg);}
  &.from-276-deg {@include wind-rotate(96deg);}
  &.from-277-deg {@include wind-rotate(97deg);}
  &.from-278-deg {@include wind-rotate(98deg);}
  &.from-279-deg {@include wind-rotate(99deg);}
  &.from-280-deg {@include wind-rotate(100deg);}
  &.from-281-deg {@include wind-rotate(101deg);}
  &.from-282-deg {@include wind-rotate(102deg);}
  &.from-283-deg {@include wind-rotate(103deg);}
  &.from-284-deg {@include wind-rotate(104deg);}
  &.from-285-deg {@include wind-rotate(105deg);}
  &.from-286-deg {@include wind-rotate(106deg);}
  &.from-287-deg {@include wind-rotate(107deg);}
  &.from-288-deg {@include wind-rotate(108deg);}
  &.from-289-deg {@include wind-rotate(109deg);}
  &.from-290-deg {@include wind-rotate(110deg);}
  &.from-291-deg {@include wind-rotate(111deg);}
  &.from-292-deg {@include wind-rotate(112deg);}
  &.from-293-deg {@include wind-rotate(113deg);}
  &.from-294-deg {@include wind-rotate(114deg);}
  &.from-295-deg {@include wind-rotate(115deg);}
  &.from-296-deg {@include wind-rotate(116deg);}
  &.from-297-deg {@include wind-rotate(117deg);}
  &.from-298-deg {@include wind-rotate(118deg);}
  &.from-299-deg {@include wind-rotate(119deg);}
  &.from-300-deg {@include wind-rotate(120deg);}
  &.from-301-deg {@include wind-rotate(121deg);}
  &.from-302-deg {@include wind-rotate(122deg);}
  &.from-303-deg {@include wind-rotate(123deg);}
  &.from-304-deg {@include wind-rotate(124deg);}
  &.from-305-deg {@include wind-rotate(125deg);}
  &.from-306-deg {@include wind-rotate(126deg);}
  &.from-307-deg {@include wind-rotate(127deg);}
  &.from-308-deg {@include wind-rotate(128deg);}
  &.from-309-deg {@include wind-rotate(129deg);}
  &.from-310-deg {@include wind-rotate(130deg);}
  &.from-311-deg {@include wind-rotate(131deg);}
  &.from-312-deg {@include wind-rotate(132deg);}
  &.from-313-deg {@include wind-rotate(133deg);}
  &.from-314-deg {@include wind-rotate(134deg);}
  &.from-315-deg {@include wind-rotate(135deg);}
  &.from-316-deg {@include wind-rotate(136deg);}
  &.from-317-deg {@include wind-rotate(137deg);}
  &.from-318-deg {@include wind-rotate(138deg);}
  &.from-319-deg {@include wind-rotate(139deg);}
  &.from-320-deg {@include wind-rotate(140deg);}
  &.from-321-deg {@include wind-rotate(141deg);}
  &.from-322-deg {@include wind-rotate(142deg);}
  &.from-323-deg {@include wind-rotate(143deg);}
  &.from-324-deg {@include wind-rotate(144deg);}
  &.from-325-deg {@include wind-rotate(145deg);}
  &.from-326-deg {@include wind-rotate(146deg);}
  &.from-327-deg {@include wind-rotate(147deg);}
  &.from-328-deg {@include wind-rotate(148deg);}
  &.from-329-deg {@include wind-rotate(149deg);}
  &.from-330-deg {@include wind-rotate(150deg);}
  &.from-331-deg {@include wind-rotate(151deg);}
  &.from-332-deg {@include wind-rotate(152deg);}
  &.from-333-deg {@include wind-rotate(153deg);}
  &.from-334-deg {@include wind-rotate(154deg);}
  &.from-335-deg {@include wind-rotate(155deg);}
  &.from-336-deg {@include wind-rotate(156deg);}
  &.from-337-deg {@include wind-rotate(157deg);}
  &.from-338-deg {@include wind-rotate(158deg);}
  &.from-339-deg {@include wind-rotate(159deg);}
  &.from-340-deg {@include wind-rotate(160deg);}
  &.from-341-deg {@include wind-rotate(161deg);}
  &.from-342-deg {@include wind-rotate(162deg);}
  &.from-343-deg {@include wind-rotate(163deg);}
  &.from-344-deg {@include wind-rotate(164deg);}
  &.from-345-deg {@include wind-rotate(165deg);}
  &.from-346-deg {@include wind-rotate(166deg);}
  &.from-347-deg {@include wind-rotate(167deg);}
  &.from-348-deg {@include wind-rotate(168deg);}
  &.from-349-deg {@include wind-rotate(169deg);}
  &.from-350-deg {@include wind-rotate(170deg);}
  &.from-351-deg {@include wind-rotate(171deg);}
  &.from-352-deg {@include wind-rotate(172deg);}
  &.from-353-deg {@include wind-rotate(173deg);}
  &.from-354-deg {@include wind-rotate(174deg);}
  &.from-355-deg {@include wind-rotate(175deg);}
  &.from-356-deg {@include wind-rotate(176deg);}
  &.from-357-deg {@include wind-rotate(177deg);}
  &.from-358-deg {@include wind-rotate(178deg);}
  &.from-359-deg {@include wind-rotate(179deg);}
  &.from-360-deg {@include wind-rotate(180deg);}
}

.wi-wind-towards-n    {
  @extend .wi-wind, .towards-0-deg;
}
