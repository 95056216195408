/**
 * PT Sans fonts vae been retrieved via https://gwfh.mranftl.com/fonts
 */

/* pt-sans-regular - latin-ext_latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/pt-sans/pt-sans-v17-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/pt-sans/pt-sans-v17-latin-ext_latin-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* pt-sans-italic - latin-ext_latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'PT Sans';
    font-style: italic;
    font-weight: 400;
    src: url('/assets/fonts/pt-sans/pt-sans-v17-latin-ext_latin-italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/pt-sans/pt-sans-v17-latin-ext_latin-italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* pt-sans-700 - latin-ext_latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/fonts/pt-sans/pt-sans-v17-latin-ext_latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/pt-sans/pt-sans-v17-latin-ext_latin-700.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* pt-sans-700italic - latin-ext_latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'PT Sans';
    font-style: italic;
    font-weight: 700;
    src: url('/assets/fonts/pt-sans/pt-sans-v17-latin-ext_latin-700italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/pt-sans/pt-sans-v17-latin-ext_latin-700italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }