// Custom Theming for Angular Material
@use "@angular/material" as mat;

/**
  * Material icons import
  */
@import "scss/material-icon-fonts";

/**
 * Open Sans font import
 */
// @import "../../scss/oos-open-sans-fonts";

/**
 * PT Sans font import
 */
@import "scss/pt-sans-fonts";

/**
 * Roboto font import
 */
@import "scss/roboto-fonts";

/**
 * Weather Icon font import
 */
@import "scss/weather-icons-font";

/**
 * Weather Icon Wind support
 */
@import "scss/weather-icons-wind";

// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.
@import "scss/ystm-colors";
@import "scss/variables";
@import "scss/d3-chart";
@import "scss/markdown";

@import "@angular/cdk/overlay-prebuilt.css";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.elevation-classes();
@include mat.app-background();

$custom-typography: mat.m2-define-typography-config(
  $font-family: 'Roboto, "Helvetica Neue", sans-serif',
  $headline-6: mat.m2-define-typography-level(14px, 20px, 500)
);

// $custom-typography: mat.define-typography-config(
//     $font-family: 'Roboto, "Helvetica Neue", sans-serif',
// );

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ystm-client-primary: mat.m2-define-palette($ystm-blue, 900);
$ystm-client-accent: mat.m2-define-palette($ystm-red, 900);

// The warn palette is optional (defaults to red).
$ystm-client-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$ystm-client-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $ystm-client-primary,
      accent: $ystm-client-accent,
      warn: $ystm-client-warn
    ),
    // typography: $custom-typography,
    density: 0
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ystm-client-theme);
@include mat.all-component-typographies($custom-typography);

// .ystm-client-dark-theme {
//   $ystm-client-dark-primary: mat.define-palette($ystm-blue, 900);
//   $ystm-client-dark-accent: mat.define-palette($ystm-red, 900);
//   $ystm-client-dark-theme: mat.define-dark-theme(
//     (
//       color: (
//         primary: $ystm-client-dark-primary,
//         accent: $ystm-client-dark-accent,
//       ),
//     )
//   );

//   @include mat.button-color($ystm-client-dark-theme);
// }

.ystm-dialog-theme {
  $ystm-dialog-primary: mat.m2-define-palette($ystm-blue, 900);
  $ystm-dialog-accent: mat.m2-define-palette($ystm-blue, 600);
  $ystm-dialog-theme: mat.m2-define-light-theme(
    (
      color: (
        primary: $ystm-dialog-primary,
        accent: $ystm-dialog-accent
      )
    )
  );

  // @include mat.button-color($ystm-dialog-theme);
  @include mat.all-component-colors($ystm-dialog-theme);

  // .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  //     color: rgba(0,0,0,0.95);
  // }

  .mat-mdc-select-disabled .mat-mdc-select-value {
    color: rgba(0, 0, 0, 0.9);
  }
  .mdc-text-field--disabled .mdc-text-field__input {
    color: rgba(0, 0, 0, 0.9);
  }
  .mat-mdc-slide-toggle .mdc-switch--disabled + label {
    color: rgba(0, 0, 0, 0.9);
  }
}
.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.9);
}

/* You can add global styles to this file, and also import other style files */
.error-color {
  color: mat.m2-get-color-from-palette($ystm-client-warn);
}
.accent-color {
  color: mat.m2-get-color-from-palette($ystm-client-accent);
}
.primary-color {
  color: mat.m2-get-color-from-palette($ystm-client-primary);
}

$primary-color: mat.m2-get-color-from-palette($ystm-client-primary);

.primary-background-color {
  background-color: mat.m2-get-color-from-palette($ystm-client-primary);
}

// .primary-bootstrap-card {
//     background-color: mat.get-color-from-palette($ystm-client-primary);
//     color: white;
//     margin-left: 0px;
//     padding-left: 0px;
// }

/**
 * Material Design customization for YSTM
 */
@import "scss/ystm-mat";
@import "scss/breakpoint-rules";
@import "scss/flex-tools";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, sans-serif;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  // color:$primary-color;
  // background-color: white;
  // opacity: 0.6;
}

html {
  --mdc-list-list-item-leading-icon-color: mat.m2-get-color-from-palette($ystm-client-primary);
  --mdc-list-list-item-label-text-color: mat.m2-get-color-from-palette($ystm-client-primary);
}

.mdc-list-item--with-leading-icon .mdc-list-item__start {
  --mdc-list-list-item-leading-icon-color: mat.m2-get-color-from-palette($ystm-client-primary);
}

.app-content-backdrop {
  // background-image: url("assets/img/ocean3-stripe.webp");
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: 50% 50%;
  position: relative;
  background-color: white;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}
.app-content {
  background-color: rgba(255, 255, 255, 0.92);
  flex: 1 0 auto;
  //opacity: 0.8;
}

.mat-body-2 {
  color: mat.m2-get-color-from-palette($ystm-client-primary);
  text-decoration-color: mat.m2-get-color-from-palette($ystm-client-primary);
  text-decoration-style: solid;
  b,
  strong {
    font-weight: 700;
  }
  h1 {
    font-family: Roboto, sans-serif;
    font-size: 36px;
    line-height: 39px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  h2 {
    font-family: Roboto, sans-serif;
    font-size: 30px;
    line-height: 33px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  h3 {
    font-family: Roboto, sans-serif;
    font-size: 24px;
    line-height: 26px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  h4 {
    font-family: Roboto, sans-serif;
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  a:not(.mdc-button) {
    color: mat.m2-get-color-from-palette($ystm-client-primary);
    text-decoration-color: mat.m2-get-color-from-palette($ystm-client-primary);
    text-decoration-line: underline;
    text-decoration-style: solid;
  }

  p {
    font-size: 15px;
    line-height: 20px;
    @include bs-gt-md {
      font-size: 16px;
      line-height: 22px;
    }
    @include bs-gt-lg {
      font-size: 18px;
      line-height: 24px;
    }
  }
  ul {
    font-size: 15px;
    line-height: 20px;
    > li {
      margin-bottom: 8px;
      font-size: 15px;
      line-height: 20px;
      @include bs-gt-md {
        font-size: 16px;
        line-height: 22px;
      }
      @include bs-gt-lg {
        font-size: 18px;
        line-height: 24px;
      }
    }
    @include bs-gt-md {
      font-size: 16px;
      line-height: 22px;
    }
    @include bs-gt-lg {
      font-size: 18px;
      line-height: 24px;
    }
  }

  section,
  app-club-home-section {
    padding-bottom: 24px;
    &.dark {
      //   @include MOBILE_TABLET {
      //     background-color: #e3f2fd;
      //     padding: 24px;
      //     border-radius: 8px;
      //     margin: 12px 0px;
      //   }
      background-color: #e3f2fd;
      padding: 24px;
      border-radius: 8px;
      margin: 12px 0px;
    }
    &.light {
      //   @include MOBILE_TABLET {
      //     background-color: #ffffff;
      //     padding: 24px;
      //     border-radius: 8px;
      //     margin: 12px 0px;
      //   }
      background-color: #ffffff;
      padding: 24px;
      border-radius: 8px;
      margin: 12px 0px;
    }
  }
}

.fbLink {
  vertical-align: middle;
  margin: 16px;
  cursor: pointer;
  a {
    color: mat.m2-get-color-from-palette($ystm-client-primary);
    text-decoration-color: mat.m2-get-color-from-palette($ystm-client-primary);
    text-decoration-line: none;
    text-decoration-style: solid;
  }
}
.fbImg {
  width: 20px;
  height: auto;
  margin-right: 16px;
  vertical-align: middle;
}

.clubImage {
  width: 100%;
  height: auto;
}
.clubImageSpace {
  width: 100%;
  height: auto;
  margin-top: 1em;
  margin-bottom: 1em;
}

.frs {
  flex: 1 1 auto;
}

.center {
  //text-align: center !important;
  text-align: center;
}

.vcenter {
  vertical-align: middle;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.top {
  vertical-align: top;
}

// taken from bootstrap 4:
.img-rounded {
  border-radius: 6px;
}

.vspace {
  height: 2em;
}

.vspace-s {
  height: 16px;
}

.vspace-xs {
  height: 8px;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

.clickable {
  cursor: pointer;
}

.event-table-description {
  > ul {
    padding-inline-start: 16px !important;
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
  }
}

/**
 * Add external link icon to all external links
 */
a[href^="http:"],
a[href^="https:"] {
  padding-right: 1.5rem;
  &:after {
    content: "\e89e";
    font-family: Material Icons;
    margin-left: 0.3rem;
    display: inline-block; /* So we can set a width */
    width: 0;

    // float: right;
    // display: flex;
    // align-items: center;
  }
}

.full-width-input {
  width: 100%;
}

/**
 * Material Design customization for YSTM
 */
.weather-forecast-open-meteo-day div.mat-expansion-panel-body {
  padding: 0 0 16px;
}
.mat-mdc-dialog-container .mdc-dialog__title {
  font-size: 20px;
  line-height: 30px;
  color: #204b78 !important;
  text-decoration-color: #204b78 !important;
  text-decoration-style: solid !important;
  &.primary {
    font-size: 20px !important;
    line-height: 30px !important;
    background-color: #204b78 !important;
    color: white !important;
    text-decoration-color: white !important;
    text-decoration-style: solid !important;
  }
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color: #204b78 !important;
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 1);
}

// Avoid blury font in form fields, see https://github.com/material-components/material-components-web/issues/5813
.mdc-floating-label {
  will-change: unset !important;
}

.display-none {
  display: none !important;
}

// customization for app-date-time-range-picker
.date-time-range-picker-special-date {
  background-color: red;
}

// customization of app-date-time-range-picker
// @include mat.datepicker-date-range-colors( hotpink, teal, yellow, purple);
// wrong green: #46a35e
// right blue: #204b78 -> rgba(32, 75, 120, 1);
// pink must become light blue: rgba(0, 0, 0, 0.87);
// i comparison range: rgba(249, 171, 0, 0.2);
// @include mat.datepicker-date-range-colors( rgba(32, 75, 120, 0.5), teal, yellow, purple);
@include mat.datepicker-date-range-colors(
  rgba(32, 75, 120, 0.2),
  rgba(249, 171, 0, 0.2),
  rgba(244, 67, 54, 0.2),
  rgb(244, 67, 54)
);

.cdk-global-scrollblock {
  position: static !important;
  width: initial !important;
  overflow-y: inherit !important;
}

// Heading with icon to apply info-tip
.heading-icon {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.mat-navIcon {
  margin-right: 8px;
}
