/**
 * Material Design customization for YSTM
 */

mat-mdc-unelevated-button.mat-unthemed {
    --mdc-filled-button-container-color: #fff;
    --mdc-filled-button-label-text-color: mat.get-color-from-palette($ystm-client-primary) !important;
}

.mat-mdc-button:not(:disabled) {
    //--mdc-text-button-label-text-color: $primary-color !important;
    color: $primary-color !important;
}

mat-card-title.mat-mdc-card-title {
    padding: 8px 16px !important;
}

/**
 * Styling of external link buttons which may show URLs
 * We usually display them as mat-mdc-button
 */ 
a[href^="http:"],
a[href^="https:"] {
    width: fit-content;
    max-width: 100%;
    text-decoration-line: none !important;
    .mdc-button__label {
        width: calc(100%); // calc is important here, do not use "100%" alone
        text-overflow: ellipsis !important;
        overflow: hidden;
        white-space: nowrap;
    }
}

.mat-expansion-indicator::after {
    color: $primary-color !important;
}

