/**
 * Roboto fonts have been retrieved via https://gwfh.mranftl.com/fonts
 */

/* roboto-100 - latin-ext_latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    src: url('/assets/fonts/roboto/roboto-v30-latin-ext_latin-100.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/roboto/roboto-v30-latin-ext_latin-100.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-100italic - latin-ext_latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100;
    src: url('/assets/fonts/roboto/roboto-v30-latin-ext_latin-100italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/roboto/roboto-v30-latin-ext_latin-100italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-300 - latin-ext_latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('/assets/fonts/roboto/roboto-v30-latin-ext_latin-300.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/roboto/roboto-v30-latin-ext_latin-300.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-300italic - latin-ext_latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    src: url('/assets/fonts/roboto/roboto-v30-latin-ext_latin-300italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/roboto/roboto-v30-latin-ext_latin-300italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-regular - latin-ext_latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/roboto/roboto-v30-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/roboto/roboto-v30-latin-ext_latin-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-italic - latin-ext_latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: url('/assets/fonts/roboto/roboto-v30-latin-ext_latin-italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/roboto/roboto-v30-latin-ext_latin-italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-500 - latin-ext_latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('/assets/fonts/roboto/roboto-v30-latin-ext_latin-500.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/roboto/roboto-v30-latin-ext_latin-500.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-500italic - latin-ext_latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src: url('/assets/fonts/roboto/roboto-v30-latin-ext_latin-500italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/roboto/roboto-v30-latin-ext_latin-500italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-700 - latin-ext_latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/fonts/roboto/roboto-v30-latin-ext_latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/roboto/roboto-v30-latin-ext_latin-700.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-700italic - latin-ext_latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src: url('/assets/fonts/roboto/roboto-v30-latin-ext_latin-700italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/roboto/roboto-v30-latin-ext_latin-700italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-900 - latin-ext_latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url('/assets/fonts/roboto/roboto-v30-latin-ext_latin-900.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/roboto/roboto-v30-latin-ext_latin-900.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-900italic - latin-ext_latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 900;
    src: url('/assets/fonts/roboto/roboto-v30-latin-ext_latin-900italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/roboto/roboto-v30-latin-ext_latin-900italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }