/**
 * breakpoint rules for ystm-client
 *
 - This rules will replace old bootrap rules in ystm-client over time
 *
 * For ystm-client we need to differentiate between the following main breakpoints:
 * - MOBILE: XSmall
 * - TABLET: Small
 * - DESKTOP: Medium, Large, XLarge
 * 
 * The Angular Material v14 breakpoints as of January 2023 are:
 * - (max-width: 599.98px): XSmall
 * - (min-width: 600px) and (max-width: 959.98px): Small
 * - (min-width: 960px) and (max-width: 1279.98px): Medium
 * - (min-width: 1280px) and (max-width: 1919.98px): Large
 * - (min-width: 1920px): XLarge
 */

/**
 * maximal width for DESKTOP, TABLET and MOBILE
 */
$DESKTOP-max-width: 1360px;
$TABLET-max-width: 768px;
$MOBILE-max-width: 100%;

/**
  * horizontal default margins for DESKTOP, TABLET and MOBILE
  */
$DESKTOP-margin: 80px;
$TABLET-margin: 32px;
$MOBILE-margin: 20px;

/**
  * maximal inner width for DESKTOP, TABLET and MOBILE
  */
$DESKTOP-inner-max-width: calc($DESKTOP-max-width - 2 * $DESKTOP-margin);
$TABLET-inner-max-width: calc($TABLET-max-width - 2 * $TABLET-margin);
$MOBILE-inner-max-width: calc($MOBILE-max-width - 2 * $MOBILE-margin);

/**
  * media queries for DESKTOP, TABLET and MOBILE
  */
@mixin MOBILE {
    @media (max-width: 599.98px) {
        @content;
    }
}
@mixin gt-MOBILE {
    @media (min-width: 600px) {
        @content;
    }
}
@mixin TABLET {
    @media (min-width: 600px) and (max-width: 959.98px) {
        @content;
    }
}
@mixin gt-TABLET {
    @media (min-width: 960px) {
        @content;
    }
}
@mixin MOBILE_TABLET {
    @media (max-width: 959.98px) {
        @content;
    }
}
@mixin DESKTOP {
    @media (min-width: 960px) {
        @content;
    }
}

/**
  * media queries for  XSmall, Small, Medium, Large and XLarge
  */
@mixin XSmall {
    @media (max-width: 599.98px) {
        @content;
    }
}
@mixin gt-XSmall {
    @media (min-width: 600px) {
        @content;
    }
}
@mixin Small {
    @media (min-width: 600px) and (max-width: 959.98px) {
        @content;
    }
}
@mixin gt-Small {
    @media (min-width: 960px) {
        @content;
    }
}
@mixin Medium {
    @media (min-width: 960px) and (max-width: 1279.98px) {
        @content;
    }
}
@mixin gt-Medium {
    @media (min-width: 1280px) {
        @content;
    }
}
@mixin Large {
    @media (min-width: 1280px) and (max-width: 1919.98px) {
        @content;
    }
}
@mixin gt-Large {
    @media (min-width: 1920px) {
        @content;
    }
}
@mixin XLarge {
    @media (min-width: 1920px) {
        @content;
    }
}

