/**
 * Material Icons Fonts
 * https://fonts.google.com/icons?selected=Material+Icons
 * 
 * Material Icons are available in five styles.
 * The icons are based on the core Material Design principles and metrics.
 * 
 * Styles: 
 *  - Filled (default)
 *  - Outlined
 *  - Round
 *  - Sharp
 *  - Two Tone
 * 
 * Weight: 400 (Regular)
 * 
 * Note: Do not mix up with Material Symbol Icons. This are new variable fonts
 */

@font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
        url(/assets/fonts/material-icons/material-icons-regular.woff2) format("woff2"),
        url(/assets/fonts/material-icons/material-icons-regular.ttf) format("truetype");
}

.material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
}

@font-face {
    font-family: "Material Icons Outlined";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
        url(/assets/fonts/material-icons/material-icons-outlined-regular.woff2) format("woff2"),
        url(/assets/fonts/material-icons/material-icons-outlined-regular.otf) format("opentype");
}

.material-icons-outlined {
    font-family: "Material Icons Outlined";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
}

@font-face {
    font-family: "Material Icons Round";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/assets/fonts/material-icons/material-icons-round-regular.woff2) format("woff2")
        url(/assets/fonts/material-icons/material-icons-round-regular.otf) format("opentype");
}

.material-icons-round {
    font-family: "Material Icons Round";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
}

@font-face {
    font-family: "Material Icons Sharp";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
        url(/assets/fonts/material-icons/material-icons-sharp-regular.woff2) format("woff2"),
        url(/assets/fonts/material-icons/material-icons-sharp-regular.otf) format("opentype");
}
.material-icons-sharp {
    font-family: "Material Icons Sharp";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
}

@font-face {
    font-family: "Material Icons Two Tone";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
        url(/assets/fonts/material-icons/material-icons-two-tone-regular.woff2) format("woff2"),
        url(/assets/fonts/material-icons/material-icons-two-tone-regular.otf) format("opentype");
}
body {
    --google-font-color-materialiconstwotone: none;
}

.material-icons-two-tone {
    font-family: "Material Icons Two Tone";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
}
