svg.d3-chart {
    width: 100%;
    height: 100%;
    .tick path,
    .tick line {
        stroke: grey;
        stroke-width: 4px;
    }
    .tick text {
        fill: lightgrey;
    }
    .x-axis path {
        stroke: grey;
        stroke-width: 4px;
    }
}

svg.d3-pv-energy-chart {
    width: 100%;
    height: 100%;
    .tick path,
    .tick line {
        //stroke: grey;
        stroke-width: 1px;
    }
    .tick text {
        // fill: lightgrey;
        font-size: 8px;
    }
    .x-axis path {
        //stroke: grey;
        stroke-width: 1px;
    }
}
