/** 
 * Default definitions from Angular Material, v15
*/
// $dark-primary-text: rgba(black, 0.87);
// $dark-secondary-text: rgba(black, 0.54);
// $dark-disabled-text: rgba(black, 0.38);
// $dark-dividers: rgba(black, 0.12);
// $dark-focused: rgba(black, 0.12);
// $light-primary-text: white;
// $light-secondary-text: rgba(white, 0.7);
// $light-disabled-text: rgba(white, 0.5);
// $light-dividers: rgba(white, 0.12);
// $light-focused: rgba(white, 0.12);

/**
 * Definitions from existing website
 */
 // $dark-primary-text: #204a78;
 $dark-primary-text: #000000;
 $light-primary-text: #ffffff;
 //$light-disabled-text: rgba(green, 0.5);
 //$disabled-text: rgba(green, 0.5);



/**
 *  Major color primary: #204a78 (blue)
 *  Major color accent: #a32825 (red)
 */

/**
 * blue pallette for YSTM developed from color 204a78 using https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
 */

  $ystm-blue: (
    50: #e5f2f7,
    100: #bedded,
    200: #99c9e1,
    300: #77b3d5,
    400: #5fa4cd,
    500: #4b95c7,
    600: #4188bb,
    700: #3677a9,
    800: #2d6798,
    900: #204b78, // intended color
    contrast: (
      50: $dark-primary-text,
      100: $dark-primary-text,
      200: $dark-primary-text,
      300: $dark-primary-text,
      400: $dark-primary-text,
      500: $light-primary-text,
      600: $light-primary-text,
      700: $light-primary-text,
      800: $light-primary-text,
      900: $light-primary-text,
    ),
  );

// Angular 9 approach
//  $ystm-blue: (
//   50: #e3f2fd,
//   100: #bbdefb,
//   200: #90caf9,
//   300: #64b5f6,
//   400: #42a5f5,
//   500: #204a78, // was material color #2196f3,
//   600: #1e88e5,
//   700: #1976d2,
//   800: #1565c0,
//   900: #0d47a1,
//   A100: #82b1ff,
//   A200: #448aff,
//   A400: #2979ff,
//   A700: #2962ff,
//   contrast: (
//     50: $dark-primary-text,
//     100: $dark-primary-text,
//     200: $dark-primary-text,
//     300: $dark-primary-text,
//     400: $dark-primary-text,
//     500: $light-primary-text,
//     600: $light-primary-text,
//     700: $light-primary-text,
//     800: $light-primary-text,
//     900: $light-primary-text,
//     A100: $dark-primary-text,
//     A200: $light-primary-text,
//     A400: $light-primary-text,
//     A700: $light-primary-text,
//   )
// );
$ystm-red: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #f44336,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #a32825, // was material claor #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

// Angular 9 approach
// $ystm-red: (
//   50: #ffebee,
//   100: #ffcdd2,
//   200: #ef9a9a,
//   300: #e57373,
//   400: #ef5350,
//   500: #f44336,
//   600: #e53935,
//   700: #d32f2f,
//   800: #c62828,
//   900: #a32825, // was material claor #b71c1c,
//   A100: #ff8a80,
//   A200: #ff5252,
//   A400: #ff1744,
//   A700: #d50000,
//   contrast: (
//     50: $dark-primary-text,
//     100: $dark-primary-text,
//     200: $dark-primary-text,
//     300: $dark-primary-text,
//     400: $dark-primary-text,
//     500: $light-primary-text,
//     600: $light-primary-text,
//     700: $light-primary-text,
//     800: $light-primary-text,
//     900: $light-primary-text,
//     A100: $dark-primary-text,
//     A200: $light-primary-text,
//     A400: $light-primary-text,
//     A700: $light-primary-text,
//   )
// );


$mvneco-grey: (
  50: #fafafa,
  100: #f5f5f5,
  200: #eeeeee,
  300: #e0e0e0,
  400: #bdbdbd,
  500: #9e9e9e,
  600: #757575, 
  700: #6f6e6f, // becomes primary color, was material color  #616161,
  800: #424242,
  900: #212121,
  A100: #ffffff,
  A200: #eeeeee,
  A400: #bdbdbd,
  A700: #616161,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);

// Alias for alternate spelling.
$mvneco-gray: $mvneco-grey;

