// // Base Variables
// $wi-path:                        "../assets/fonts/weather-icons" !default;
// $wi-version:                     "2.0" !default;
// $wi-css-prefix:                  wi !default;
// $wi-font-family:                 weathericons !default;

@font-face {
    font-family: 'weathericons';
    src: url(../assets/fonts/weather-icons/weathericons-regular-webfont.eot);
    src: url('../assets/fonts/weather-icons/weathericons-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/weather-icons/weathericons-regular-webfont.woff2') format('woff2'),
         url('../assets/fonts/weather-icons/weathericons-regular-webfont.woff') format('woff'),
         url('../assets/fonts/weather-icons/weathericons-regular-webfont.ttf') format('truetype'),
         url('../assets/fonts/weather-icons/weathericons-regular-webfont.svg#weather_iconsregular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.wi {
  display: inline-block;
  font-family: 'weathericons';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wi-fw {
  width: 1.4em;
  text-align: center;
}

@mixin wi-icon-rotate($degrees, $rotation) {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation});
  -webkit-transform: rotate($degrees);
      -ms-transform: rotate($degrees);
          transform: rotate($degrees);
}

@mixin wi-icon-flip($horiz, $vert, $rotation) {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation});
  -webkit-transform: scale($horiz, $vert);
      -ms-transform: scale($horiz, $vert);
          transform: scale($horiz, $vert);
}

.wi-rotate-90  { @include wi-icon-rotate(90deg, 1);  }
.wi-rotate-180 { @include wi-icon-rotate(180deg, 2); }
.wi-rotate-270 { @include wi-icon-rotate(270deg, 3); }

.wi-flip-horizontal { @include wi-icon-flip(-1, 1, 0); }
.wi-flip-vertical   { @include wi-icon-flip(1, -1, 2); }
