// Wind Variables 2.0

$wind: "\f0b1";

.wi-wind:before { content: $wind; }

@mixin wind-rotate( $val: 0deg ) {
  -webkit-transform: rotate($val);
  -moz-transform: rotate($val);
  -ms-transform: rotate($val);
  -o-transform: rotate($val);
  transform: rotate($val);
}

@import "classes-wind-degrees.scss";