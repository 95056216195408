markdown.markdown.regular {
	h1 {
		font-size: 2em;
	}
	h2 {
		font-size: 1.5em;
	}
	h3 {
		font-size: 1.17em;
	}
	h4 {
		font-size: 1em;
	}
	h5 {
		font-size: 0.83em;
	}
	h6 {
		font-size: 0.67em;
	}
	p {
		font:
			400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
		margin: 0 0 4px;
	}
	ul {
		font-size: 14px;
		line-height: 18px;
		margin-block-start: 4px;
		margin-block-end: 4px;
		> li {
			font-size: 14px;
			line-height: 18px;
			margin-bottom: 2px;
		}
	}
}
