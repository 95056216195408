.form-block {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    //align-items: center;
    .form-element {
        flex: 0 0 100%;
        display: block;
        box-sizing: border-box;
        padding: 4px;
        @include gt-XSmall {
            padding: 6px; // was 8px
        }
        &.fe-100-gt-sm-33 {
            @include gt-Small {
                flex: 0 0 33%;
            }
        }
        &.fe-100-gt-sm-50 {
            @include gt-Small {
                flex: 0 0 50%;
            }
        }
        &.fe-100-gt-xs-50 {
            @include gt-XSmall {
                flex: 0 0 50%;
            }
        }
        &.fe-50-gt-sm-33 {
            flex: 0 0 50%;
            @include gt-Small {
                flex: 0 0 33%;
            }
        }
        &.fe-30 {
            flex: 0 0 30%;
        }
        &.fe-40 {
            flex: 0 0 40%;
        }
        &.fe-50 {
            flex: 0 0 50%;
        }
        &.fe-60 {
            flex: 0 0 60%;
        }
        &.fe-auto {
            flex: 0 1 auto;
        }
        &.fe-180px {
            flex: 0 0 180px;
        }
        &.fe-320px {
            flex: 0 0 320px;
        }
    }
}

.row-space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.row-wrap-xs-column {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    //align-items: center;
    @include XSmall {
        flex-direction: column;
    }
    .row-element {
        flex: 0 0 100%;
        display: block;
        box-sizing: border-box;
        padding: 4px;
        @include gt-XSmall {
            padding: 6px; // was 8px
        }
        &.re-100-gt-sm-33 {
            @include gt-Small {
                flex: 0 0 33%;
            }
        }
        &.re-100-gt-sm-50 {
            @include gt-Small {
                flex: 0 0 50%;
            }
        }
    }
}
.row-sm-column {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    @include XSmall {
        flex-direction: column;
    }
    @include Small {
        flex-direction: column;
    }
    .row-element {
        flex: 0 0 100%;
        display: block;
        box-sizing: border-box;
        padding: 4px;
        @include gt-XSmall {
            padding: 6px; // was 8px
        }
        &.re-100-gt-sm-33 {
            @include gt-Small {
                flex: 0 0 33%;
            }
        }
        &.re-100-gt-sm-50 {
            @include gt-Small {
                flex: 0 0 50%;
            }
        }
    }
}

.flex-block-row-wrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .block-element-50 {
        flex: 0 0 50%;
        display: block;
        box-sizing: border-box;
    }
    .block-element-100 {
        flex: 0 0 100%;
        display: block;
        box-sizing: border-box;
    }
}

.flex-block-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    .block-element-50 {
        flex: 0 0 50%;
        display: block;
        box-sizing: border-box;
    }
    .block-element-100 {
        flex: 0 0 100%;
        display: block;
        box-sizing: border-box;
    }
    .block-element-32px {
        flex: 0 0 32px;
        display: block;
        box-sizing: border-box;
    }
    .block-element-48px {
        flex: 0 0 48px;
        display: block;
        box-sizing: border-box;
    }
    .block-element-180px {
        flex: 0 0 180px;
        display: block;
        box-sizing: border-box;
    }
    .block-element-320px {
        flex: 0 0 320px;
        display: block;
        box-sizing: border-box;
    }
    .block-element-360px {
        flex: 0 0 360px;
        display: block;
        box-sizing: border-box;
    }
    .block-element-auto {
        flex: 0 1 auto;
        display: block;
        box-sizing: border-box;
    }
}

.flex-block-row-center-center {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .block-element-50 {
        flex: 0 0 50%;
        display: block;
        box-sizing: border-box;
    }
}


.flex-block-row-space-between-center {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .block-element-50 {
        flex: 0 0 50%;
        display: block;
        box-sizing: border-box;
    }
}

.flex-block-row-flex-end-center {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .block-element-50 {
        flex: 0 0 50%;
        display: block;
        box-sizing: border-box;
    }
}


.flex-block-row-flex-start-center {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .block-element-50 {
        flex: 0 0 50%;
        display: block;
        box-sizing: border-box;
    }
}

.flex-block-row-flex-start-baseline {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    .block-element-50 {
        flex: 0 0 50%;
        display: block;
        box-sizing: border-box;
    }
}

.flex-block-column {
    display: flex;
    flex-direction: column;
}
